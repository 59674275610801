option:checked {
    background: gold;
}

#firstPart, #secondPart, #middlePart, #thirdPart, #fourthPart {
    display: inline;
    padding: 0 3px 3px 3px;
}

#firstPart {
    background: #00d5d5;
}

#secondPart {
    background: #90c42c;
}

#middlePart{
    background: #1155CC;
}

#thirdPart {
    background: #ad00ad;
}

#fourthPart {
    background: #f8a306;
}

select {
    background: none;
    width: fit-content;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
}

select::-ms-expand {
    display: none;
}